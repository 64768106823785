$(document).ready(function () {

    /********************************
    *           Customizer          *
    ********************************/
    var body = $('body');

    $('.gallery-drawer-toggle').on('click', function () {
        $('.gallery-drawer').addClass('open');
    });
    $('.gallery-drawer-close').on('click', function () {
        $('.gallery-drawer').removeClass('open');
    });
    // if ($('.gallery-drawer-content').length > 0) {
    //     $('.gallery-drawer-content').perfectScrollbar({
    //         theme: "dark"
    //     });
    // }

    $('.location-drawer-toggle').on('click', function () {
        $('.location-drawer').addClass('open');
    });
    $('.location-drawer-close').on('click', function () {
        $('.location-drawer').removeClass('open');
    });
    // if ($('.location-drawer-content').length > 0) {
    //     $('.location-drawer-content').perfectScrollbar({
    //         theme: "dark"
    //     });
    // }

    $('.clients-drawer-toggle').on('click', function () {
        $('.clients-drawer').addClass('open');
    });
    $('.clients-drawer-close').on('click', function () {
        $('.clients-drawer').removeClass('open');
    });
    // if ($('.clients-drawer-content').length > 0) {
    //     $('.clients-drawer-content').perfectScrollbar({
    //         theme: "dark"
    //     });
    // }

    $('.news-drawer-toggle').on('click', function () {
        $('.news-drawer').addClass('open');
    });
    $('.news-drawer-close').on('click', function () {
        $('.news-drawer').removeClass('open');
    });
    // if ($('.news-drawer-content').length > 0) {
    //     $('.news-drawer-content').perfectScrollbar({
    //         theme: "dark"
    //     });
    // }
});